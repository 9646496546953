<template>
    <div>
        <a-modal
            @cancel="close"
            :width="'40%'"
            centered
            :header="null"
            :footer="null"
            :visible="modal.visible"
        >
            <h5>{{ modal.edit ? "Edit" : "Add new" }} Inclusion</h5>
            <a-row :gutter="16" class="mt-5">
                <a-form-model ref="modal" :model="modal">
                    <a-col :span="24">
                        <a-form-model-item
                            prop="title"
                            label="Title"
                            required
                            :rules="req('Please enter the Name')"
                        >
                            <a-input
                                v-model="modal.title"
                                placeholder="Enter the Name"
                            ></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item prop="body" label="Legal Text" required :rules="req('Please enter the legal body')">
                            <a-textarea
                                v-model="modal.body"
                                placeholder="Enter the legal body"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-form-model>
            </a-row>
            <div class="dF mt-5" style="justify-content: flex-end">
                <a-button @click="close" size="large">CANCEL</a-button>
                <a-button
                    @click="submit"
                    type="primary"
                    size="large"
                    class="ml-3"
                    >{{ modal.edit ? "UPDATE" : "CREATE" }}</a-button
                >
            </div>
        </a-modal>
        <a-table class="white-table" :columns="columns" :data-source="data" :rowKey="(e) => e.id">
            <div slot="action" slot-scope="obj">
                <a-icon
                    type="edit"
                    class="cursor-pointer hover:text-primary"
                    @click="edit(obj)"
                />
                <i
                    class="fa fa-trash ml-3 cursor-pointer hover:text-primary"
                    @click="deleteThis(obj)"
                />
            </div>
        </a-table>
        <div class="dF aC" style="gap: 20px">
            <div
                @click="makeNewFn"
                class="dF aC p-2"
                style="color: var(--orange); cursor: pointer; gap: 20px"
                :style="
                    data.length >= 20
                        ? 'background-color:#f1f1f1; cursor: not-allowed'
                        : ''
                "
            >
                <a-icon style="font-size: 25px" type="plus-circle" />
                <span>Add Inclusion</span>
            </div>
            <a-alert
                v-if="data.length >= 20"
                message="You can maximum add 20 inclusions"
				type="warning"
				show-icon
            />
        </div>
    </div>
</template>

<script>
import { formatNumber } from "bh-mod";
export default {
    props: {
        data: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            modal: {
                edit: false,
                body: "",
                title: "",
                id: Date.now() + "",
            },
            columns: [
                {
                    title: "Title",
                    key: "date",
                    dataIndex: "title",
                },
                {
                    title: "Body",
                    key: "depositAmount",
                    dataIndex: "body",
                },
                {
                    title: "Action",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
        };
    },
    computed: {
        deps() {
            return this.data.map((x, xI) => ({ ...x, order: xI + 1 }));
        },
    },
    methods: {
        edit(item) {
            item = JSON.parse(JSON.stringify(item));
            this.modal = {
                ...this.modal,
                edit: true,
                visible: true,
                ...item,
            };
        },
        submit() {
            this.$refs.modal &&
                this.$refs.modal.validate((valid) => {
                    if (valid) {
                        let { visible, edit, ...obj } = this.modal;
                        obj = JSON.parse(JSON.stringify(obj));

                        if (this.modal.edit) {
                            this.$store.commit("SET_OPTIONS", {
                                type: "app",
                                where: "inclusions",
                                action: "arrayEdit",
                                what: obj,
                            });
                        } else {
                            obj.id = Date.now() + "";
                            this.$store.commit("SET_OPTIONS", {
                                type: "app",
                                where: "inclusions",
                                action: "arrayAdd",
                                what: obj,
                            });
                        }
                        this.close();
                    } else {
                        return false;
                    }
                });
        },
        req: (msg) => ({ required: true, message: msg }),
        close() {
            this.$refs.modal && this.$refs.modal.resetFields();
            this.modal.visible = false;
            this.modal.edit = false;
        },
        makeNewFn() {
            if (this.data.length < 20) {
                this.modal = {
                    edit: false,
                    visible: true,
                    body: "",
                    title: "",
                    id: Date.now() + "",
                };
                this.modal.visible = true;
            }
        },
        formatNumber,
        deleteThis(e) {
            this.$confirm({
                title: "Delete Inclusion",
                content: (h) => (
                    <div>Do you want to delete this Inclusion ?</div>
                ),
                okText: "DELETE",
                okType: "danger",
                cancelText: "CANCEL",
                centered: true,
                onOk: () => {
                    let newObj = this.data.filter((x) => x.id !== e.id);
                    this.$store.commit("SET_OPTIONS", {
                        where: "inclusions",
                        what: newObj,
                    });
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
    },
};
</script>

<style>
</style>
