<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab" width="230">
			<a-card v-if="activeTab == 'docuSign'">
				<div class="">
					<h2>DocuSign Integration</h2>
					<div class="mt-4">
						<span style="color: var(--med-gray)">Status: </span>
						<span v-if="integrated.active && !removedIntegration">Connected</span>
						<span v-else class="text-danger">Not Linked to DocuSign</span>
					</div>
					<div v-if="integrated.active &&
						integratedUser &&
						!removedIntegration
						">
						<div>
							<span>
								<span style="color: var(--med-gray)">Name:
								</span>
								<span class="text-success">{{
									integratedUser.name
								}}</span>
							</span>
							<span class="text-success ml-1" style="position: relative; top: 5px">
								<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
									style="width: 20px; height: 20px">
									<path fill-rule="evenodd"
										d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
										clip-rule="evenodd" />
								</svg>
							</span>
						</div>
						<div>
							<span style="color: var(--med-gray)">Email: </span>
							<span>{{ integratedUser.email }}</span>
						</div>
					</div>
					<hr />

					<div class="mt-4">
						<a-button @click="integrate(true)" type="danger" v-if="integrated.active && !removedIntegration"
							size="large" icon="minus">Remove Integration</a-button>
						<a :href="integrated.connectUrl" v-else-if="integrated.connectUrl">
							<a-button type="primary" size="large" icon="link">Integrate with DocuSign</a-button>
						</a>
					</div>
				</div>
			</a-card>

			<a-card v-else-if="activeTab === 'participants'">
				<ParticipantsTable :data="participantsList" @edit="editParticipant" />
				<div @click="participantVisible" class="mt-3 dF" style="color: var(--orange); cursor: pointer">
					<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
					<p>Add new participant</p>
				</div>
				<a-modal @cancel="closeParticipant" :width="'40%'" centered :header="null" :footer="null"
					:visible="newParticipant.visible">
					<div>
						<h5>
							{{
								newParticipant.edit ? "Edit" : "Add"
							}}
							Participant
						</h5>
						<a-row :gutter="16" class="mt-5">
							<a-form-model ref="newParticipant" :model="newParticipant">
								<a-col :span="24">
									<a-form-model-item prop="name" label="Name" required
										:rules="req('Please enter the Name')">
										<a-input v-model="newParticipant.name" placeholder="Enter the Name"></a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-form-model-item prop="email" label="Email Address" required :rules="req(
										'Please enter the Email Address'
									)
										">
										<a-input v-model="newParticipant.email" placeholder="Email Address"></a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-form-model-item prop="company" label="Company">
										<a-input v-model="newParticipant.company"
											placeholder="Enter the Company Name"></a-input>
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-form-model-item prop="role" label="Role">
										<a-input v-model="newParticipant.role" placeholder="Enter the Role"></a-input>
									</a-form-model-item>
								</a-col>
							</a-form-model>
						</a-row>
						<a-alert v-if="newParticipant.edit"
							message="Changes to participant information only apply to new transactions, not those already completed or pending with signatures."
							type="warning" show-icon />
						<div class="dF mt-5" style="justify-content: flex-end">
							<a-button @click="closeParticipant" size="large">CANCEL</a-button>
							<a-button @click="addParticipant" type="primary" size="large" class="ml-3">{{
								newParticipant.edit ? "UPDATE" : "CREATE"
							}}</a-button>
						</div>
					</div>
				</a-modal>
			</a-card>

			<a-card v-else-if="activeTab === 'sellersAgents'">
				<SellersAgentsTab />
			</a-card>

			<a-card v-else-if="activeTab === 'irrevocableDate'">
				<a-card title="Irrevocable Date Days">
					<a-form-model class="mt-3">
						<a-form-model-item label="Days">
							<a-input type="number" v-model="irrevocableDateDays" size="large" style="width:300px; max-width:100%;" />
						</a-form-model-item>

						<a-alert type="info" show-icon class="mt-3" style="width: max-content;">
							<template slot="message">
								Enter the number of days which will set Irrevocable Date automation from the contact creation date.
							</template>
						</a-alert>
					</a-form-model>

					<a-button class="pull-right" size="large" type="primary" @click="saveIrrevocableDateDays"
						:loading="loading">SAVE</a-button>
				</a-card>
			</a-card>

			<a-card v-else-if="activeTab === 'inclusions'">
				<InclusionsTab :data="inclusionList" />
			</a-card>

			<a-card v-else-if="activeTab === 'depositStructure'">
				<DepositTable :data="depositList" @editStructure="editDepositStructure" @edit="editDeposit"
					:depositStructure="selectedDepositStructure" @onSelect="(e) => selectedDepositStructure = e" />

				<a-row :gutter="16">
					<a-col :span="12">
						<div @click="depositStructureVisible" class="mt-3 dF" style="color: var(--orange); cursor: pointer">
							<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
							<p>Add new deposit structure</p>
						</div>
					</a-col>

					<a-col :span="12">
						<div v-if="selectedDepositStructure && depositList.length" @click="depositVisible" class="mt-3 dF"
							style="color: var(--orange); cursor: pointer">
							<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
							<p>Add new deposit</p>
						</div>
					</a-col>
				</a-row>

				<a-modal centered :header="null" :footer="null" :visible="newDepositStructure.visible"
					@cancel="closeDepositStructure">
					<div>
						<h5>
							{{ !newDepositStructure.edit ? "Add new" : "Edit" }} Deposit Structure
						</h5>
						<a-row :gutter="16" class="mt-5">
							<a-col :span="24">
								<a-form-model ref="newDepositStructure" :model="newDepositStructure">
									<a-form-model-item prop="name" label="Deposit Structure Name" required>
										<a-input v-model="newDepositStructure.name" placeholder="Deposit Structure Name" />
									</a-form-model-item>
								</a-form-model>
							</a-col>
						</a-row>
						<div class="dF mt-2" style="justify-content: flex-end">
							<a-button @click="newDepositStructure.visible = false" size="large">CANCEL</a-button>
							<a-button @click="addDepositStructure" type="primary" size="large" class="ml-3">{{
								newDepositStructure.edit ? "UPDATE" : "CREATE"
							}}</a-button>
						</div>
					</div>
				</a-modal>

				<a-modal :width="'40%'" centered :header="null" :footer="null" :visible="newDeposit.visible"
					@cancel="closeDeposit">
					<div>
						<h5>
							{{ !newDeposit.edit ? "Add new" : "Edit" }} Deposit
						</h5>
						<a-row :gutter="16" class="mt-5">
							<a-form-model ref="newDeposit" :model="newDeposit">
								<a-col :span="24">
									<a-form-model-item prop="date.value" label="Deposit Date"
										help="Enter the number of days after Offer date for this deposit" required :rules="{
											validator: checkDepositDate,
											trigger: 'blur',
										}">
										<a-input-number v-model="newDeposit.date.value" :min="0" :max="99999"
											placeholder="Days" />
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-row :gutter="16" class="mt-3">
										<a-col :lg="6" :sm="12">
											<a-form-model-item prop="amount.value" label="Deposit Amount">
												<a-input-number v-if="!newDeposit.amount.perc
													" style="width: 100%" :min="0" :formatter="(value) =>
		`$ ${value}`.replace(
			/\B(?=(\d{3})+(?!\d))/g,
			','
		)
		" :parser="(value) =>
		value.replace(
			/\$\s?|(,*)/g,
			''
		)
		" v-model="newDeposit.amount.value
		" suffix="RMB" />
												<a-input-number v-model="newDeposit.amount.value
													" style="width: 100%" v-else :min="0" :max="100" :formatter="(value) => `${value}%`
		" :parser="(value) =>
		value.replace(
			'%',
			''
		)
		" />
											</a-form-model-item>
										</a-col>
										<a-col :lg="6" :sm="12">
											<a-form-model-item prop="amount.perc" :label="`Amount Type`">
												<a-switch checked-children="%" un-checked-children="$" v-model="newDeposit.amount.perc
													" />
											</a-form-model-item>
										</a-col>
									</a-row>
								</a-col>
							</a-form-model>
						</a-row>
						<div class="dF mt-5" style="justify-content: flex-end">
							<a-button @click="newDeposit.visible = false" size="large">CANCEL</a-button>
							<a-button @click="addDeposit" type="primary" size="large" class="ml-3">{{
								newDeposit.edit ? "UPDATE" : "CREATE"
							}}</a-button>
						</div>
					</div>
				</a-modal>
			</a-card>

			<a-card v-else-if="activeTab === 'addOnDepositStructure'">
				<AddOnsDepositTable :data="addOnDepositList" @edit="editAddOnDeposit" />

				<div @click="addOnDepositVisible" class="mt-3 dF" style="color: var(--orange); cursor: pointer">
					<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
					<p>Add new Add On deposit</p>
				</div>
				<a-modal :width="'40%'" centered :header="null" :footer="null" :visible="newAddOnDeposit.visible"
					@cancel="closeAddOnDeposit">
					<div>
						<h5>
							{{
								!newAddOnDeposit.edit ? "Add new" : "Edit"
							}}
							Add On Deposit
						</h5>
						<a-row :gutter="16" class="mt-5">
							<a-form-model ref="newAddOnDeposit" :model="newAddOnDeposit">
								<a-col :span="24">
									<a-form-model-item prop="date.value" label="Deposit Date"
										help="Enter the number of days after Offer date for this add on deposit" required
										:rules="{
											validator: checkAddOnDepositDate,
											trigger: 'blur',
										}">
										<a-input-number v-model="newAddOnDeposit.date.value" :min="0" :max="99999"
											placeholder="Days" />
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-row :gutter="16" class="mt-3">
										<a-col :lg="6" :sm="12">
											<a-form-model-item prop="amount.value" label="Deposit Amount">
												<a-input-number v-if="!newAddOnDeposit.amount
													.perc
													" style="width: 100%" :min="0" :formatter="(value) =>
		`$ ${value}`.replace(
			/\B(?=(\d{3})+(?!\d))/g,
			','
		)
		" :parser="(value) =>
		value.replace(
			/\$\s?|(,*)/g,
			''
		)
		" v-model="newAddOnDeposit.amount
		.value
		" suffix="RMB" />
												<a-input-number v-model="newAddOnDeposit.amount
													.value
													" style="width: 100%" v-else :min="0" :max="100" :formatter="(value) => `${value}%`
		" :parser="(value) =>
		value.replace(
			'%',
			''
		)
		" />
											</a-form-model-item>
										</a-col>
										<a-col :lg="6" :sm="12">
											<a-form-model-item prop="amount.perc" :label="`Amount Type`">
												<a-switch checked-children="%" un-checked-children="$" v-model="newAddOnDeposit.amount
													.perc
													" />
											</a-form-model-item>
										</a-col>
									</a-row>
								</a-col>
							</a-form-model>
						</a-row>
						<div class="dF mt-5" style="justify-content: flex-end">
							<a-button @click="newAddOnDeposit.visible = false" size="large">CANCEL</a-button>
							<a-button @click="addAddOnDeposit" type="primary" size="large" class="ml-3">{{
								newAddOnDeposit.edit ? "UPDATE" : "CREATE"
							}}</a-button>
						</div>
					</div>
				</a-modal>
			</a-card>
		</SettingsTab>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import ParticipantsTable from "@/components/forms/ParticipantsTable";
import DepositTable from "@/components/forms/DepositTable";
import AddOnsDepositTable from "@/components/forms/AddOnsDepositTable"
import InclusionsTab from "@/components/forms/InclusionsTab";
import SellersAgentsTab from "@/components/forms/SellersAgentsTab";
import { formatDate } from "bh-mod";

export default {
	name: "Settings_Page",
	components: {
		BHLoading,
		SettingsTab,
		ParticipantsTable,
		SellersAgentsTab,
		DepositTable,
		InclusionsTab,
		AddOnsDepositTable
	},
	data() {
		return {
			loading: false,
			removedIntegration: false,
			activeTab: 'docuSign',
			irrevocableDateDays: null,
			newParticipant: {
				id: "",
				edit: false,
				visible: false,
				name: "",
				email: "",
				company: "",
				role: "",
			},
			newDeposit: {
				order: 0,
				edit: false,
				visible: false,
				date: {
					value: 1,
					days: true,
				},
				amount: {
					value: 1,
					perc: true,
				},
			},
			newAddOnDeposit: {
				order: 0,
				edit: false,
				visible: false,
				date: {
					value: 1,
					days: true,
				},
				amount: {
					value: 1,
					perc: true,
				},
			},
			newDepositStructure: {
				edit: false,
				visible: false,
				id: new Date().getTime(),
				name: '',
				items: [],
			},
			selectedDepositStructure: null,
		};
	},
	watch: {
		appSettings: {
			deep: true,
			handler(val) {
				if (val) {
					let settings = JSON.parse(JSON.stringify(val));
					delete settings.address;
					delete settings.addons;
					this.$api.post(`/settings/:instance/transactions`, {
						options: settings,
					}).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					});
				}
			},
		},
	},
	computed: {
		integratedUser() {
			return (this.integrated && this.integrated.userInfo) || false;
		},
		integrated() {
			return this.$store.state.appData.vendors && this.$store.state.appData.vendors.docusign || {};
		},
		appSettings() {
			let app = this.$store.state.appData.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},
		menuList() {
			const tablist = [
				{ label: 'Default Participants', id: 'participants' },
				{ label: 'Seller\'s Agents', id: 'sellersAgents', info: 'Use this Seller\'s Agents setting to complete RECO information guide & self represent form.' },
				{ label: 'Inclusion Information', id: 'inclusions' },
				{ label: 'Irrevocable Date', id: 'irrevocableDate' },
				{ label: 'Deposit Structures', id: 'depositStructure', info: 'Use this deposit setting to set up your Unit Deposit Structure. This deposit structure should also be used if your Parking, Locker and Bike Rack are predefined or offered for purchase at point of sale.' },
			]

			if (this.instance.productType === "highrise") {
				tablist.push({
					label: "Add Ons Deposit Structure",
					id: "addOnDepositStructure",
					info: "Use this deposit structure when transacting Add Ons (Parking, Locker, Bike Rack) after unit sale. This will allow you to set up a different deposit structure for these Add Ons."
				});
			}
			return [
				{
					label: 'General Settings', id: 'sub1', children: [{ label: 'DocuSign Integration', id: 'docuSign' }],
				},
				{
					label: 'Customization', id: 'sub2', children: tablist,
					info: 'Warning: If you add/update/delete any customizations, those changes will be reflected in the newly transactions created after the action only.'
				}
			];
		},
		participantsList() {
			return this.appSettings.participants || [];
		},
		inclusionList() {
			return this.appSettings.inclusions || [];
		},
		checklist() {
			return this.appSettings.checklist || [];
		},
		depositList() {
			return this.appSettings.deposits || [];
		},
		currentDepositList() {
			let depositIndex = 0
			if (this.selectedDepositStructure) {
				depositIndex = this.depositList.findIndex(d => d.id === this.selectedDepositStructure.id)
			}
			return this.appSettings.deposits[depositIndex] && this.appSettings.deposits[depositIndex].items || [];
		},
		addOnDepositList() {
			return this.appSettings.addOnDeposits || [];
		},
		instance() {
			return this.$store.state.instance;
		},
	},
	methods: {
		formatDate,
		integrate(remove = false) {
			if (remove) {
				let self = this;
				this.$confirm({
					title: `Are you sure you want to remove DocuSign integration?`,
					okText:'Remove',
					okType: 'danger',
					centered: true,
					onOk() {
						self.loading = true;
						self.$api.delete(`/vendors/:instance/${self.integrated.id}`).then(() => {
							self.loading = false;
							self.removedIntegration = true;
						}).catch((err) => {
							self.loading = false;
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						});
					}
				})
			} else {
				location.href = this.integrated.connectUrl;
			}
		},
		checkDepositDate(rule, value, callback) {
			let prevError = this.currentDepositList.find((x) => {
				return x.order < this.newDeposit.order && x.date.value > value;
			});

			if (prevError)
				return callback(
					new Error(
						"Deposit Days must be greater than previous Deposits"
					)
				);

			let futureError = this.currentDepositList.find((x) => {
				if (x.order > this.newDeposit.order) {
					return x.date.value < value;
				}
				return false;
			});

			if (futureError)
				return callback(
					new Error(
						"Deposit Days must be lesser than future Deposits"
					)
				);

			callback();
		},
		checkAddOnDepositDate(rule, value, callback) {
			let prevError = this.addOnDepositList.find((x) => {
				return (
					x.order < this.newAddOnDeposit.order && x.date.value > value
				);
			});

			if (prevError)
				return callback(
					new Error(
						"Deposit Days must be greater than previous Deposits"
					)
				);

			let futureError = this.addOnDepositList.find((x) => {
				if (x.order > this.newAddOnDeposit.order) {
					return x.date.value < value;
				}
				return false;
			});

			if (futureError)
				return callback(
					new Error(
						"Deposit Days must be lesser than future Deposits"
					)
				);

			callback();
		},
		editParticipant(item) {
			let { email, role, company, name, id } = item;
			if (!id) {
				id = new Date().getTime();

				this.$store.commit("SET_OPTIONS", {
					type: "app",
					where: "participants",
					action: "arrayEdit",
					what: { id, email, role, company, name },
					key: "email",
				});
			}

			this.newParticipant = {
				...this.newParticipant,
				edit: true,
				visible: true,
				id: id || email,
				...item,
			};
		},
		editDepositStructure(item) {
			item = JSON.parse(JSON.stringify(item));
			this.newDepositStructure = {
				...this.newDepositStructure,
				edit: true,
				visible: true,
				...item,
			};
		},
		editDeposit(item) {
			item = JSON.parse(JSON.stringify(item));
			this.newDeposit = {
				...this.newDeposit,
				edit: true,
				visible: true,
				...item,
			};
		},
		editAddOnDeposit(item) {
			item = JSON.parse(JSON.stringify(item));
			this.newAddOnDeposit = {
				...this.newAddOnDeposit,
				edit: true,
				visible: true,
				...item,
			};
		},

		importSettings() {
			if (!this.appSettings) return;
			if (
				typeof this.appSettings !== "object" ||
				this.appSettings === null
			)
				return;
			if (!Object.keys(this.appSettings).length) return;
		},
		req: (msg) => ({ required: true, message: msg }),
		onTabChange(data) {
			this.activeTab = data;
		},
		participantVisible() {
			this.newParticipant = {
				id: new Date().getTime(),
				edit: false,
				visible: true,
				name: "",
				email: "",
				company: "",
				role: "",
			}

			this.$nextTick(() => {
				if (this.$refs.newParticipant) {
					this.$refs.newParticipant.resetFields();
					this.$refs.newParticipant.clearValidate();
				}
			})
		},
		depositVisible() {
			this.newDeposit.order = this.currentDepositList.length;
			this.newDeposit.visible = true;
			this.newDeposit.edit = false;
		},
		depositStructureVisible() {
			this.newDepositStructure = {
				edit: false,
				visible: true,
				id: new Date().getTime(),
				name: '',
				items: [],
			}
		},
		addOnDepositVisible() {
			this.newAddOnDeposit.order = this.addOnDepositList.length;
			this.newAddOnDeposit.visible = true;
			this.newAddOnDeposit.edit = false;
		},
		closeDepositStructure() {
			this.$refs.newDepositStructure && this.$refs.newDepositStructure.resetFields();
			this.newDepositStructure.visible = false;
			this.newDepositStructure.edit = false;
		},
		closeDeposit() {
			this.$refs.newDeposit && this.$refs.newDeposit.resetFields();
			this.newDeposit.visible = false;
			this.newDeposit.edit = false;
		},
		closeAddOnDeposit() {
			this.$refs.newAddOnDeposit &&
				this.$refs.newAddOnDeposit.resetFields();
			this.newAddOnDeposit.visible = false;
			this.newAddOnDeposit.edit = false;
		},
		closeParticipant() {
			this.$refs.newParticipant &&
				this.$refs.newParticipant.resetFields();
			this.newParticipant.visible = false;
			this.newParticipant.edit = false;
		},

		addParticipant() {
			this.$refs.newParticipant.validate((valid) => {
				if (valid) {
					let { email, role, company, name, id } = this.newParticipant;

					if (this.newParticipant.edit) {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "participants",
							action: "arrayEdit",
							what: { id, email, role, company, name },
							key: "id",
						});
					} else {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "participants",
							action: "arrayAdd",
							what: { id, email, role, company, name },
						});
					}
					this.closeParticipant();
				} else {
					return false;
				}
			});
		},

		addDepositStructure() {
			this.$refs.newDepositStructure.validate((valid) => {
				if (valid) {
					let newDepositStructure = JSON.parse(
						JSON.stringify(this.newDepositStructure)
					);
					let { edit, visible, ...obj } = newDepositStructure;

					if (this.newDepositStructure.edit) {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "deposits",
							action: "arrayEdit",
							what: obj,
							key: "id",
						});
					} else {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "deposits",
							action: "arrayAdd",
							what: obj,
						});
					}
					this.selectedDepositStructure = { ...obj }

					this.closeDepositStructure();
				} else {
					return false;
				}
			});
		},

		addDeposit() {
			if (!this.selectedDepositStructure && this.depositList) {
				this.selectedDepositStructure = this.depositList[0];
			}
			this.$refs.newDeposit.validate((valid) => {
				if (valid) {
					let newDeposit = JSON.parse(
						JSON.stringify(this.newDeposit)
					);
					let { edit, visible, ...obj } = newDeposit;

					if (this.newDeposit.edit) {
						this.selectedDepositStructure.items = this.selectedDepositStructure.items.map((d) =>
							d.order === obj.order ? obj : d
						);
					} else {
						this.selectedDepositStructure.items.push({
							...obj,
							order: this.currentDepositList.length || 0
						})
					}

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "deposits",
						action: "arrayEdit",
						what: { ...this.selectedDepositStructure },
						key: "id",
					});

					this.closeDeposit();
				} else {
					return false;
				}
			});
		},
		addAddOnDeposit() {
			this.$refs.newAddOnDeposit.validate((valid) => {
				if (valid) {
					let newAddOnDeposit = JSON.parse(
						JSON.stringify(this.newAddOnDeposit)
					);
					let { edit, visible, ...obj } = newAddOnDeposit;

					if (this.newAddOnDeposit.edit) {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "addOnDeposits",
							action: "arrayEdit",
							what: obj,
							key: "order",
						});
					} else {
						obj.order = this.addOnDepositList.length || 0;
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "addOnDeposits",
							action: "arrayAdd",
							what: obj,
						});
					}

					this.$forceUpdate();

					this.closeAddOnDeposit();
				} else {
					return false;
				}
			});
		},

		saveIrrevocableDateDays() {
			if (this.irrevocableDateDays && Number(this.irrevocableDateDays) < 0) {
				return this.$message.error('Days value can not be less than 1')
			}

			this.$store.commit("SET_OPTIONS", {
				type: "app",
				where: "irrevocableDateDays",
				what: Number(this.irrevocableDateDays),
			});
			this.$message.success('Changes saved successfully.')
		}
	},
	mounted() {
		this.importSettings();
		let locHref = location.href;
		if (locHref.includes("docusign")) {
			this.activeTab = 'docuSign';
			this.$router.replace("/settings");
			if (locHref.includes("docusign=1")) {
				this.$message.success("DocuSign Integrated");
			}
		}

		if (this.appSettings.irrevocableDateDays) {
			this.irrevocableDateDays = this.appSettings.irrevocableDateDays
		}
	},
};
</script>

<style lang="scss">
</style>
